.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ContainerRow {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input,
label {
  display: block;
}

.AvatarListDiv {
  float: right;
  margin-left: auto;
  margin-right: 0;
}

.AvatarList {
  display: flex;
  flex-direction: row;
  padding-left: 5px;
  padding-right: 5px;
}

.Avatar {
  padding-left: 15px;
  padding-right: 15px;
  list-style-type: none;
}

.wrapper {
  width: 75%;
  height: 90vh;
  overflow: scroll;
  border: .07rem solid #dae1e7;
  border-radius: .25rem;
  box-shadow: 0 .8rem 1rem -.2rem rgba(0, 0, 0, .1), 0 .25rem .5rem -.02rem rgba(0, 0, 0, .05);
}

.bi.bi-diagram {
  box-sizing: border-box;
  width: 200%;
  height: 200%;
  border: unset;
  border-radius: unset;
  box-shadow: unset;
  background-color: #f8fafc;
  position: relative;
  z-index: 50;
  overflow: scroll;
}

.bi.bi-diagram-port {
  width: 100%;
}

.bi.bi-diagram-canvas {
  box-sizing: content-box;
  /*    width: 400vh;
    height: 400vh;*/
  border: unset;
  border-radius: unset;
  box-shadow: unset;
  position: inherit;
  overflow: inherit;
}

.bi-link-canvas-layer {
  /*    width: 200%;
    height: 200%;*/
  min-height: unset;
  pointer-events: none;
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: scroll;
}

.barButtonContainer {
  padding-left: 20px;
  padding-top: 20px;
  display: flex;
  align-items: baseline;
}

.loginBarButtonContainer {
  padding-top: 20px;
  display: flex;
  align-items: baseline;
}

.barTextfield {
  text-align: center;
}

.loginButtonContainer {
  height: 400px;
  position: relative;
}